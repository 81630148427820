<template>
  <div v-if="itemLesson" class="trainings-lesson-item--quiz" :key="itemLesson.id">
    <div v-if="showTitle" class="white--text font-weight-bold text__title">
      {{ itemLesson.name }}
    </div>
    <div class="trainings-lesson-item--quiz__body quiz__body">
      <!--Score page-->
      <div class="trainings-lesson-item--quiz__body__result" v-if="isFinish">
        <!--        Quiz! Layout: no image-->
        <div v-if="Number(layout) === 1">
          <div class="trainings-lesson-item--quiz__body__result__title">
            {{ $t('trainings.thankYouForcompletingQuestionnaire') }}
            <span v-if="allowedAttempts !== 0">
              ({{ this.userAttempts }}/{{ this.allowedAttempts }})
            </span>
          </div>
          <div class="pt-4 trainings-lesson-item--quiz__result__body__result-text">
            {{ $t('trainings.yourResult') }}
          </div>
          <div
            class="trainings-lesson-item--quiz__body__result__score"
            :style="{ color: getScoreColor(score) }"
          >
            {{ score }}%
          </div>
          <div
            v-if="score >= itemLesson.content.quizz.passingGrade && hasReplayed()"
            class="px-8 py-4 mx-auto trainings-lesson-item--quiz__body__result__edit-answer"
            style="width: 300px"
            @click="onClickEditAnswer"
          >
            {{ $t('trainings.editMyAnswers') }}
          </div>
          <div class="mx-auto px-8" style="width: 200px">
            <v-btn
              depressed
              color="#00B5A7"
              class="white--text font-weight-bold px-16"
              style="width: 100%"
              @click="onSubmitLesson(itemLesson)"
              v-if="
                (score >= itemLesson.content.quizz.passingGrade && !isDone) ||
                (!hasReplayed() && !isDone)
              "
            >
              {{ $t('trainings.next') }}
            </v-btn>
            <v-btn
              depressed
              color="#00B5A7"
              class="white--text font-weight-bold px-16"
              @click="onClickEditAnswer"
              v-else-if="hasReplayed()"
            >
              {{ $t('trainings.tryAgain') }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center" v-if="Number(layout) === 2">
          <div style="width: 45%">
            <v-img width="100%" height="550px" :src="itemLesson.content.quizz.image" />
          </div>
          <div style="width: 55%">
            <div class="font-weight-bold">
              {{ $t('trainings.thankYouForcompletingQuestionnaire') }}
              <span v-if="allowedAttempts !== 0">
                ({{ this.userAttempts }}/{{ this.allowedAttempts }})
              </span>
            </div>
            <div class="pt-4 trainings-lesson-item--quiz__result__body__result-text">
              {{ $t('trainings.yourResult') }}
            </div>
            <div
              class="trainings-lesson-item--quiz__body__result__score"
              :style="{ color: getScoreColor(score) }"
            >
              {{ score }}%
            </div>
            <div
              class="px-8 py-4 mx-auto trainings-lesson-item--quiz__body__result__edit-answer"
              style="width: 300px"
              @click="onClickEditAnswer"
              v-if="score >= itemLesson.content.quizz.passingGrade && hasReplayed()"
            >
              {{ $t('trainings.editMyAnswers') }}
            </div>
            <div class="mx-auto px-8" style="width: 200px">
              <v-btn
                depressed
                color="#00B5A7"
                class="white--text font-weight-bold px-16"
                style="width: 100%"
                @click="onSubmitLesson(itemLesson)"
                v-if="
                  (score >= itemLesson.content.quizz.passingGrade && !isDone) ||
                  (!hasReplayed() && !isDone)
                "
              >
                {{ $t('trainings.next') }}
              </v-btn>
              <v-btn
                depressed
                color="#00B5A7"
                class="white--text font-weight-bold px-16"
                @click="onClickEditAnswer"
                v-else-if="hasReplayed()"
              >
                {{ $t('trainings.tryAgain') }}
              </v-btn>
            </div>
          </div>
        </div>
        <div class="d-flex align-center" v-if="Number(layout) === 3">
          <div style="width: 55%">
            <div class="font-weight-bold">
              {{ $t('trainings.thankYouForcompletingQuestionnaire') }}
              <span v-if="allowedAttempts !== 0">
                ({{ this.userAttempts }}/{{ this.allowedAttempts }})
              </span>
            </div>
            <div class="pt-4 trainings-lesson-item--quiz__result__body__result-text">
              {{ $t('trainings.yourResult') }}
            </div>
            <div
              class="trainings-lesson-item--quiz__body__result__score"
              :style="{ color: getScoreColor(score) }"
            >
              {{ score }}%
            </div>
            <div
              class="px-8 py-4 mx-auto trainings-lesson-item--quiz__body__result__edit-answer"
              style="width: 300px"
              @click="onClickEditAnswer"
              v-if="score >= itemLesson.content.quizz.passingGrade && hasReplayed()"
            >
              {{ $t('trainings.editMyAnswers') }}
            </div>
            <div class="mx-auto px-8" style="width: 200px">
              <v-btn
                depressed
                color="#00B5A7"
                class="white--text font-weight-bold px-16"
                style="width: 100%"
                @click="onSubmitLesson(itemLesson)"
                v-if="
                  (score >= itemLesson.content.quizz.passingGrade && !isDone) ||
                  (!hasReplayed() && !isDone)
                "
              >
                {{ $t('trainings.next') }}
              </v-btn>
              <v-btn
                depressed
                color="#00B5A7"
                class="white--text font-weight-bold px-16"
                style="width: max-content"
                @click="onClickEditAnswer"
                v-else-if="hasReplayed()"
              >
                {{ $t('trainings.tryAgain') }}
              </v-btn>
            </div>
          </div>
          <div style="width: 45%">
            <v-img width="100%" height="550px" :src="itemLesson.content.quizz.image" />
          </div>
        </div>
        <div v-if="Number(layout) === 4">
          <div
            class="d-flex align-center justify-center"
            v-bind:style="{
              backgroundImage: 'url(' + itemLesson.content.quizz.image + ')',
              width: '100%',
              height: '550px',
            }"
          >
            <div class="layout-4">
              <div class="trainings-lesson-item--quiz__body__result__title">
                {{ $t('trainings.thankYouForcompletingQuestionnaire') }}
                <span v-if="allowedAttempts !== 0">
                  ({{ this.userAttempts }}/{{ this.allowedAttempts }})
                </span>
              </div>
              <div class="pt-4 trainings-lesson-item--quiz__result__body__result-text">
                {{ $t('trainings.yourResult') }}
              </div>
              <div
                class="trainings-lesson-item--quiz__body__result__score"
                :style="{ color: getScoreColor(score) }"
              >
                {{ score }}%
              </div>
              <div
                class="px-8 py-4 mx-auto trainings-lesson-item--quiz__body__result__edit-answer"
                style="width: 300px"
                @click="onClickEditAnswer"
                v-if="score >= itemLesson.content.quizz.passingGrade && hasReplayed()"
              >
                {{ $t('trainings.editMyAnswers') }}
              </div>
              <div class="mx-auto px-8" style="width: 200px">
                <v-btn
                  depressed
                  color="#00B5A7"
                  class="white--text font-weight-bold px-16"
                  style="width: 100%"
                  @click="onSubmitLesson(itemLesson)"
                  v-if="
                    (score >= itemLesson.content.quizz.passingGrade && !isDone) ||
                    (!hasReplayed() && !isDone)
                  "
                >
                  {{ $t('trainings.next') }}
                </v-btn>
                <v-btn
                  depressed
                  color="#00B5A7"
                  class="white--text font-weight-bold px-16"
                  style="width: max-content"
                  @click="onClickEditAnswer"
                  v-else-if="hasReplayed()"
                >
                  {{ $t('trainings.tryAgain') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-if="Number(layout) === 5">
          <div style="padding-top: 30px">
            <v-img
              class="mx-auto"
              style="border-radius: 8px"
              width="400px"
              height="200px"
              :src="itemLesson.content.quizz.image"
            />
          </div>
          <div>
            <div class="font-weight-bold">
              {{ $t('trainings.thankYouForcompletingQuestionnaire') }}
              <span v-if="allowedAttempts !== 0">
                ({{ this.userAttempts }}/{{ this.allowedAttempts }})
              </span>
            </div>
            <div
              class="pt-4 trainings-lesson-item--quiz__result__body__result-text"
              style="margin-top: 20px"
            >
              {{ $t('trainings.yourResult') }}
            </div>
            <div
              class="trainings-lesson-item--quiz__body__result__score"
              :style="{ color: getScoreColor(score) }"
            >
              {{ score }}%
            </div>
            <div
              class="px-8 py-4 mx-auto trainings-lesson-item--quiz__body__result__edit-answer"
              style="width: 300px"
              @click="onClickEditAnswer"
              v-if="score >= itemLesson.content.quizz.passingGrade || hasReplayed()"
            >
              {{ $t('trainings.editMyAnswers') }}
            </div>
            <div class="mx-auto px-8" style="width: 200px">
              <v-btn
                depressed
                color="#00B5A7"
                class="white--text font-weight-bold px-16"
                style="width: 100%"
                @click="onSubmitLesson(itemLesson)"
                v-if="
                  (score >= itemLesson.content.quizz.passingGrade && !isDone) ||
                  (!hasReplayed() && !isDone)
                "
              >
                {{ $t('trainings.next') }}
              </v-btn>
              <v-btn
                depressed
                color="#00B5A7"
                class="white--text font-weight-bold px-16"
                style="width: max-content"
                @click="onClickEditAnswer"
                v-else-if="hasReplayed()"
              >
                {{ $t('trainings.tryAgain') }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <!--Quiz page-->
      <div v-else>
        <div v-if="Number(layout) === 1">
          <div class="trainings-lesson-item--quiz__body__question-number">
            Question {{ curLesson.index + 1 }} :
          </div>
          <div
            v-html="curLesson.content"
            class="trainings-lesson-item--quiz__body__content"
            style="overflow-wrap: anywhere"
            v-if="!isLoadingContent"
          ></div>
          <div
            v-html="curLesson.content"
            class="trainings-lesson-item--quiz__body__content"
            v-else
            style="opacity: 0"
          ></div>
          <div class="trainings-lesson-item--quiz__body__answer">
            <div class="mt-4 trainings-lesson-item--quiz__body__answer__content">
              <v-select
                :items="curLesson.answers"
                solo
                item-text="content"
                v-on:change="onSelectAnswer"
                v-model="curAnswer"
                no-data-text="No answer"
              ></v-select>
              <v-btn
                v-if="questionList[curLesson.index] && curLesson.answers.length === 0"
                @click="onClickOk"
                >OK</v-btn
              >
              <v-btn
                v-else-if="questionList[curLesson.index] && curLesson.answers.length > 0"
                @click="onClickOk"
                :disabled="!questionList[curLesson.index].userAnswer"
                >OK</v-btn
              >
            </div>
          </div>
          <div class="trainings-lesson-item--quiz__body__process">
            <div class="py-4">Question: {{ curLesson.index + 1 }}/{{ data.length }}</div>
            <v-progress-linear
              color="#00b5a7"
              height="10"
              :value="((curLesson.index + 1) * 100) / data.length"
            ></v-progress-linear>
          </div>
        </div>
        <div v-else-if="Number(layout) === 2" class="d-flex align-center">
          <div style="width: 45%">
            <v-img
              class="left-border-radius"
              width="100%"
              height="550px"
              :src="itemLesson.content.quizz.image"
            />
          </div>
          <div style="width: 55%">
            <div class="trainings-lesson-item--quiz__body__question-number">
              Question {{ curLesson.index + 1 }} :
            </div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              style="overflow-wrap: anywhere"
              v-if="!isLoadingContent"
            ></div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              v-else
              style="opacity: 0"
            ></div>

            <div class="trainings-lesson-item--quiz__body__answer">
              <div class="trainings-lesson-item--quiz__body__answer__content">
                <v-select
                  :items="curLesson.answers"
                  solo
                  item-text="content"
                  v-on:change="onSelectAnswer"
                  v-model="curAnswer"
                ></v-select>
                <v-btn
                  v-if="this.questionList[this.curLesson.index]"
                  @click="onClickOk"
                  :disabled="!this.questionList[this.curLesson.index].userAnswer"
                  >OK</v-btn
                >
              </div>
            </div>
            <div class="trainings-lesson-item--quiz__body__process">
              <div class="py-4">Question: {{ curLesson.index + 1 }}/{{ data.length }}</div>
              <v-progress-linear
                color="#00b5a7"
                height="10"
                :value="((curLesson.index + 1) * 100) / data.length"
              ></v-progress-linear>
            </div>
          </div>
        </div>
        <div v-else-if="Number(layout) === 3" class="d-flex">
          <div style="width: 55%">
            <div class="trainings-lesson-item--quiz__body__question-number">
              Question {{ curLesson.index + 1 }} :
            </div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              style="overflow-wrap: anywhere"
              v-if="!isLoadingContent"
            ></div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              v-else
              style="opacity: 0"
            ></div>

            <div class="trainings-lesson-item--quiz__body__answer">
              <div class="trainings-lesson-item--quiz__body__answer__content">
                <v-select
                  :items="curLesson.answers"
                  solo
                  item-text="content"
                  v-on:change="onSelectAnswer"
                  v-model="curAnswer"
                ></v-select>
                <v-btn
                  v-if="this.questionList[this.curLesson.index]"
                  @click="onClickOk"
                  :disabled="!this.questionList[this.curLesson.index].userAnswer"
                  >OK</v-btn
                >
              </div>
            </div>
            <div class="trainings-lesson-item--quiz__body__process">
              <div class="py-4">Question: {{ curLesson.index + 1 }}/{{ data.length }}</div>
              <v-progress-linear
                color="#00b5a7"
                height="10"
                :value="((curLesson.index + 1) * 100) / data.length"
              ></v-progress-linear>
            </div>
          </div>
          <div style="width: 45%">
            <v-img width="100%" height="550px" :src="itemLesson.content.quizz.image" />
          </div>
        </div>
        <div
          v-else-if="Number(layout) === 4"
          class="d-flex justify-center align-center"
          v-bind:style="{
            backgroundImage: 'url(' + itemLesson.content.quizz.image + ')',
            height: '550px',
          }"
        >
          <div class="white rounded-lg px-1" style="width: 80%; height: 80%">
            <div
              class="trainings-lesson-item--quiz__body__question-number"
              style="padding-top: 40px"
            >
              Question {{ curLesson.index + 1 }} :
            </div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              style="overflow-wrap: anywhere"
              v-if="!isLoadingContent"
            ></div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              v-else
              style="opacity: 0"
            ></div>

            <div class="trainings-lesson-item--quiz__body__answer">
              <div class="trainings-lesson-item--quiz__body__answer__content">
                <v-select
                  :items="curLesson.answers"
                  solo
                  item-text="content"
                  v-on:change="onSelectAnswer"
                  v-model="curAnswer"
                ></v-select>
                <v-btn
                  @click="onClickOk"
                  v-if="this.questionList[this.curLesson.index]"
                  :disabled="!this.questionList[this.curLesson.index].userAnswer"
                  >OK</v-btn
                >
              </div>
            </div>
            <div class="trainings-lesson-item--quiz__body__process pb-4">
              <div class="py-4">Question: {{ curLesson.index + 1 }}/{{ data.length }}</div>
              <v-progress-linear
                color="#00b5a7"
                height="10"
                :value="((curLesson.index + 1) * 100) / data.length"
              ></v-progress-linear>
            </div>
          </div>
        </div>
        <div v-else-if="Number(layout) === 5">
          <div>
            <v-img
              class="mx-auto"
              style="border-radius: 8px"
              width="400px"
              height="200px"
              :src="itemLesson.content.quizz.image"
            />
          </div>
          <div>
            <div class="trainings-lesson-item--quiz__body__question-number pt-0">
              Question {{ curLesson.index + 1 }} :
            </div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              v-if="!isLoadingContent"
              style="margin-top: 20px; overflow-wrap: anywhere"
            ></div>
            <div
              v-html="curLesson.content"
              class="trainings-lesson-item--quiz__body__content"
              v-else
              style="opacity: 0"
            ></div>

            <div class="trainings-lesson-item--quiz__body__answer" style="padding-top: 20px">
              <div class="trainings-lesson-item--quiz__body__answer__content">
                <v-select
                  :items="curLesson.answers"
                  solo
                  item-text="content"
                  v-on:change="onSelectAnswer"
                  v-model="curAnswer"
                ></v-select>
                <v-btn
                  @click="onClickOk"
                  v-if="this.questionList[this.curLesson.index]"
                  :disabled="!this.questionList[this.curLesson.index].userAnswer"
                  >OK</v-btn
                >
              </div>
            </div>
            <div class="trainings-lesson-item--quiz__body__process">
              <div class="pb-4">Question: {{ curLesson.index + 1 }}/{{ data.length }}</div>
              <v-progress-linear
                color="#00b5a7"
                height="10"
                :value="((curLesson.index + 1) * 100) / data.length"
              ></v-progress-linear>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showFooter" class="trainings-lesson-item--quiz__footer">
        {{ $t('trainings.details.footer') }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import {
  SET_QUIZ_SCORE,
  TRAINING_MODULE,
} from '@/stores/umanize-app/actions/training/training.actions';
import {
  COMPLETE_LESSION_TRAINING_SECTION,
  TRAINING_SECTION_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';

const NO_LIMIT = 0;

export default {
  name: 'TrainingsSectionItemQuiz',
  props: {
    data: {
      type: Array,
    },
    item: {},
    layout: {},
    showTitle: {
      type: Boolean,
      default: () => true,
    },
    showFooter: {
      type: Boolean,
      default: () => true,
    },
    isLoading: {
      type: Boolean,
    },
    navigationAllowed: {
      type: Boolean,
    },
  },
  data() {
    return {
      curLesson: {
        index: 0,
        content: '',
        option: [],
      },
      itemLesson: this.item,
      questionList: [],
      isLoadingContent: false,
      isFinish: false,
      isDone: false,
      curAnswer: null,
      allowedAttempts: 0,
      userAttempts: 0,
      score: 0,
      finalScore: 0,
    };
  },
  mounted() {
    this.itemLesson = this.item;
    this.curLesson.content = this.data[0]?.content ?? '';
    this.curLesson.answers = this.data[0]?.answers ?? [];
    this.curLesson.index = 0;
    this.questionList = this.data;
    this.score = this.itemLesson.score ?? 0;
    this.finalScore = 0;
    this.isDone = this.itemLesson.isDone ?? false;
    this.userAttempts = this.itemLesson.nbAttempts ?? 0;
    this.allowedAttempts = this.itemLesson.content.quizz.allowedAttempts;
    this.isFinish =
      (this.userAttempts > 0 && this.userAttempts <= this.allowedAttempts) ||
      (this.allowedAttempts === NO_LIMIT && this.userAttempts > 0);

    if (
      !this.isFinish &&
      ((this.allowedAttempts > 0 && this.userAttempts >= this.allowedAttempts) ||
        (this.allowedAttempts === NO_LIMIT && this.userAttempts > 0))
    ) {
      this.finalScore = this.score;
      this.isFinish = true;
      this.isDone = true;
    }

    this.adjustContentStyle();
  },
  methods: {
    ...mapActions(TRAINING_MODULE, [SET_QUIZ_SCORE]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [COMPLETE_LESSION_TRAINING_SECTION]),
    updateNavigationAllowed(allowed) {
      this.$emit('update-navigationAllowed', allowed);
    },

    setCurLesson(nextIndex) {
      this.curLesson.content = this.questionList[nextIndex]?.content ?? '';
      this.curLesson.answers = this.questionList[nextIndex]?.answers;
      this.curLesson.index = nextIndex;
      this.curAnswer = this.questionList[nextIndex]?.userAnswer;
      this.adjustContentStyle();
    },
    hasReplayed() {
      if (this.isDone) {
        return false;
      }

      if (this.allowedAttempts === NO_LIMIT) {
        return true;
      }

      return this.userAttempts < this.allowedAttempts;
    },
    adjustContentStyle() {
      if (this.isFinish) return;
      this.isLoadingContent = true;
      const questionLength = this.curLesson?.content?.length;
      setTimeout(() => {
        const numChildren = document.getElementsByClassName(
          'trainings-lesson-item--quiz__body__content',
        )[0].childElementCount;
        if (numChildren === 0) {
          if (questionLength > 100 && window.innerWidth < 480) {
            document.getElementsByClassName(
              'trainings-lesson-item--quiz__body__content',
            )[0].style.alignContent = 'unset !important';
          } else
            document.getElementsByClassName(
              'trainings-lesson-item--quiz__body__content',
            )[0].style.alignContent = 'center !important';
        } else if (numChildren === 1) {
          if (questionLength > 100 && window.innerWidth < 480) {
            document.getElementsByClassName(
              'trainings-lesson-item--quiz__body__content',
            )[0].style.alignContent = 'unset !important';
          } else
            document.getElementsByClassName(
              'trainings-lesson-item--quiz__body__content',
            )[0].style.alignContent = 'center !important';
        } else {
          document.getElementsByClassName(
            'trainings-lesson-item--quiz__body__content',
          )[0].style.alignContent = 'unset !important';
        }
        this.isLoadingContent = false;
      }, 200);
    },
    onClickOk() {
      this.updateNavigationAllowed(false);
      const nextIndex = this.curLesson.index + 1;
      if (nextIndex > 0 && nextIndex <= this.data.length) {
        this.$emit('set-isInProgressCurrentLesson', true);
      }
      if (nextIndex === this.data.length) {
        this.isFinish = true;
        this.score = this.getScore();

        this.userAttempts++;

        this.itemLesson = {
          ...this.itemLesson,
          score: this.score,
          nbAttempts: this.userAttempts,
          isDone: false,
        };

        this.isDone = this.itemLesson.isDone;

        this.$emit('submit', {
          ...this.itemLesson,
        });
        return;
      }
      this.setCurLesson(nextIndex);
    },
    onClickEditAnswer() {
      const nextIndex = 0;
      this.isFinish = false;
      this.setCurLesson(nextIndex);
    },
    onSelectAnswer(curAnswer) {
      this.questionList[this.curLesson.index].userAnswer = curAnswer;
    },
    getScore() {
      let numCorrect = 0;
      let maxScore = 0;
      this.questionList.forEach((ele) => {
        maxScore += ele.score;
        ele.answers.forEach((eleAnswer) => {
          if (eleAnswer.content === ele?.userAnswer && eleAnswer.isCorrect) {
            numCorrect += ele.score;
          }
        });
      });
      const score = numCorrect;
      this.finalScore = (score * 100) / maxScore;
      if (maxScore === 0) return 100;
      return Math.round((score * 100) / maxScore);
    },
    getScoreColor(score) {
      if (score >= this.itemLesson.content.quizz.passingGrade) return '#00B5A7';
      return '#FC5345';
    },
    onSubmitLesson(itemLesson) {
      this.updateNavigationAllowed(true);
      this.$emit('set-isInProgressCurrentLesson', false);
      this.$emit('submit', {
        ...itemLesson,
        isDone: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/styles/core/variables';

.trainings-lesson-item--quiz {
  border-radius: 5px;
  position: relative;
  padding: 15px 60px;

  .text__title {
    height: 50px;
    font-size: $xx-large-font-size;
    margin-bottom: 1rem;
    display: none;
  }

  .layout-4 {
    width: 80%;
    height: 80%;
    border-radius: 4px;
    background: white;
    padding: 1rem;
  }

  &__body {
    background: white;
    color: black;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &.quiz__body {
      height: auto;
    }

    &__result {
      padding-bottom: 100px;

      &__title {
        font-weight: bold;
        padding-top: 150px;
      }

      &__score {
        font-weight: bold;
        font-size: $xx-large-font-size;
      }

      &__edit-answer {
        min-height: 100px;
        cursor: pointer;
      }
    }

    &__question-number {
      padding-top: 94px !important;
      font-weight: bold;
    }

    &__content {
      margin-top: 16px !important;
      padding-top: 5px;
      display: inline-grid;
      padding-inline: 50px;
      text-align: left;
    }

    &__answer {
      padding-top: 0px !important;
      width: 50%;
      margin: auto;

      &__content {
        display: flex;
        justify-content: center;

        button {
          margin-left: 1rem;
        }

        .v-btn:not(.v-btn--round).v-size--default {
          height: 48px !important;
          min-height: 48px !important;
        }
      }
    }

    &__process {
      width: 30%;
      margin: auto;
      min-height: 200px;
    }
  }

  &__footer {
    color: black;
    background: white;
    box-shadow: 0px -3px 6px #00000029;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: calc(100% - 1rem);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;
  }

  @include breakpoint(medium) {
    padding: 0;
    .text__title {
      display: block;
    }
    &__body {
      &__question-number {
        padding-top: 100px;
      }

      &__process {
        padding-top: 20px;
      }
    }
    &__footer {
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .v-menu__content {
    left: 45px !important;
  }
  .v-input__slot {
    width: 185px;
  }
}
.v-list-item__title {
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 900px;
}
</style>
